const LearningPlanList = (props) => {
    const { learningPlans, onLpClick, onBackClick, onPageClick } = props;

    const onClickHandler = (learningPlan) => {
        onLpClick(learningPlan);
    }

    return (
        <div className="container p-3 mt-3">

            <div className="card-header">
                <h6 className="p-3">Select a learning plan</h6>
            </div>

            <div className="card-body">

                <div className="row pt-3">
                    {
                        learningPlans?.length > 0 ?
                            <div className="col">
                                <ul className="list-group-flush learning-plan-list">
                                    {learningPlans.map(lp => {
                                        return <li key={lp.id} className="list-group-item bg-hover-grey-superlight"><a className="btn btn-link app-link-themed" onClick={() => onClickHandler(lp)}>{lp.name}</a></li>
                                    })}
                                </ul>
                            </div>
                            :
                            <h5 className="text-center color-grey">No learning plans found.</h5>
                    }
                </div>
            </div>


        </div>
    )
}

export default LearningPlanList;
