import React, { useEffect, useState } from "react";
//import LearningPlanSearch from './LearningPlanSearch';
import Sessions from "./Sessions";
import LearningPlanList from "./LearningPlanList";
//import TrainingPrograms from "./TrainingPrograms";
import { useAppContext } from '../contexts/AppContext';
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import Complete from "./Complete";

function LearningPlans() {
    
    const [learningPlans, setLearningPlans] = useState([]);
    const [filteredPlans, setFilteredPlans] = useState([]);
    const [userLearningPlans, setUserLearningPlans] = useState([]);
    const [search, setSearch] = useState('');
    const [groupedSessionsForCourseId, setGroupedSessionsForCourseId] = useState([]); // Add by Suraj on 13 Jun
     
    const [locations, setLocations] = useState([]);
    const [filteredSessions, setFilteredSessions] = useState([]);
    const [groupedSessions, setGroupedSessions] = useState([]);
    const [sessionFromDate, setSessionFromDate] = useState('');
    const [sessionToDate, setSessionToDate] = useState('');
    const [loading, setLoading] = useState(false);
    // const [goToPrograms, setGoToPrograms] = useState(true);
    // const [selectedTrainingProgram, setSelectedTrainingProgram] = useState();
    const [selectedPlan, setSelectedPlan] = useState();
    
    const { language, setLanguage, currentUser, setLocation, location } =useAppContext();
   // const languages = JSON.parse(process.env.REACT_APP_LANGUAGES)
    const [languages, setLanguages] = useState([]);
    const [coursesIds, setCourseIds] = useState([]);
   // const [fileContent, setFileContent] = useState('');
    // useEffect(() => {
    //     selectedPlan &&
    //         getAllCoursesSessions(selectedPlan)
    // }, [selectedPlan])


//
const [locationOptions, setlocationOptions] = useState([]);
const [selectLocation, setselectLocation] = useState([]);
//
const [CompletePage, setCompletePage] = useState(false);

    useEffect(() => {
        (async () => {
            const trainingPrograms = await getTrainingPrograms();
            if (!currentUser.isPowerUser && currentUser.activeTrainingRequirement) {
                let userTrainingProgram = trainingPrograms.trainingPrograms.filter(tp => tp.trainingName == currentUser.activeTrainingRequirement)[0]
                if (!userTrainingProgram || userTrainingProgram === "") {
                    getLearningPlans()
                } else {
                    getLearningPlans(userTrainingProgram.trainingCode)
                }
            } else {
                getLearningPlans()
            }
        })();

        getAllLocations();
        getAllLanguages();

    //     fetch('/language.json',{
    //         headers: {
    //           'Cache-Control': 'no-cache',
    //         }
    //       })
    //   .then(response => {
    //     if (!response.ok) {
    //       throw new Error('Network response was not ok');
    //     }
    //     return response.json();
    //   })
    //   .then(data => {
    //     setFileContent(data);
    //   })
    //   .catch(error => console.error('Error fetching JSON file:', error));


    }, [])
//    console.log(fileContent);
//    console.log(datalan);
   
    useEffect(() => {
        if (search) {
            getLearningPlans(search);
        }
    }, [search]);

    const getTrainingPrograms = async () => {
        // const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/config/trainingprograms${window.location.search}`, { withCredentials: true });
        // const trainingProgramsJson = response.data;
        
        // return JSON.parse(trainingProgramsJson);
        const response =  await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/trainingprograms`,
             {
                
            }
           );
      
          
            const trainingProgramsJson = response.data;
           
            return trainingProgramsJson;
    }

    const getLearningPlans = async (query, page) => {

        setLoading(true);
        // let url = `${process.env.REACT_APP_API_DOMAIN}/learningplan?page_size=200&return_courses=true${query ? `&search_text=${query}` : ""}` //with paging
      //  let url = `${process.env.REACT_APP_API_DOMAIN}/learningplan${window.location.search}&sort_attr=name&return_courses=true&page_size=200&${query ? `&search_text=${query}` : ""}`//live
      //  const response = await axios.get(url, { withCredentials: true });
        const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/learningplan`,
            {
                user_id: 0,
                username: "",
                auth_code: "",
            hash: "",
                sortAttr: "name",
                returnCourses: true,
                pageSize: 0
              }
          );
          if(query)
            {
                 
               const filteredItems = response.data.items.filter(item => item.code.includes(query));
             
               setLearningPlans(filteredItems);
               var plans = [...filteredItems];
           plans = plans.filter(lp => lp.code.endsWith(language)).sort(x => x.name)
   
           setFilteredPlans(plans);
            }
            else{
          
               setLearningPlans(response.data.items);
               var plans = [...response.data.items];
           plans = plans.filter(lp => lp.code.endsWith(language)).sort(x => x.name)
   
           setFilteredPlans(plans);
            }
        setLoading(false);
    }

    const getAllCoursesSessions = async (learningPlan) => {

        var learningPlanCourses = learningPlan.courses

        var courseIds = [learningPlanCourses.map(course => course.id_course)[0]]
        //var courseIds = learningPlanCourses.map(course => course.id_course)

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_DOMAIN}/course/sessions${window.location.search}`, {
                courseIds
            }, { withCredentials: true });
            if (response.data.length > 0) {
                let sessions = response.data.filter(session => session.additional_fields.length > 0 && session.additional_fields.find(field => field.title === "CohortID").value)
                //setSessions(sessions);

                //if (!currentUser.isPowerUser) {
                  
                    sessions = sessions.filter(session => new Date(session.start_date) >= new Date());
                //}

                let AllCohorts = sessions.map(s => s.additional_fields.find(field => field.title === "CohortID").value)
                let uniqueCohorts = [...new Set(AllCohorts)]

                var _groupedSessions = uniqueCohorts.map(cohort => {
                    return { "cohortId": cohort, "sessions": sessions.filter(session => session.additional_fields.find(field => field.title === "CohortID").value == cohort) }
                })

                _groupedSessions = _groupedSessions.sort(function (a, b) {
                    if (a.sessions[0].name < b.sessions[0].name) {
                        return -1;
                    }
                    if (a.sessions[0].name > b.sessions[0].name) {
                        return 1;
                    }
                    return 0;
                });

                setGroupedSessions(_groupedSessions);
                setGroupedSessionsForCourseId(_groupedSessions); // added by suraj 13 Jun
                setFilteredSessions(_groupedSessions);
            }
            setLoading(false);

        } catch (error) {
            console.log(error);
        }
    }

    //added by excel soft to display the sessions details using seat count api
    const getAllCoursesSessionsForSessionPage = async (learningPlan) => {
       
        var learningPlanCourses = learningPlan.courses

       // var courseIds = [learningPlanCourses.map(course => course.id_course)[0]]
        var courseIds = learningPlanCourses.map(course => course.id_course)
        setCourseIds(courseIds);
        try {
  // Add by suraj 13 jun
          //  const courseSessionResponse = await axios.post(`${process.env.REACT_APP_API_DOMAIN}/course/sessions${window.location.search}`, {
          //      courseIds
          //  }, { withCredentials: true });
         //   setGroupedSessionsForCourseId(courseSessionResponse.data);
// till here
        //    const response = await axios.post(`${process.env.REACT_APP_API_DOMAIN}/course/sessionsdtl${window.location.search}`, {
         //       courseIds
         //   }, { withCredentials: true });
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/sessiondetails`, {
                CourseIds: courseIds
            });
            if (response.data.length > 0) {
                let sessions = response.data.filter(session => session.additional_fields.length > 0 && session.additional_fields.find(field => field.name === "CohortID").value)
                //setSessions(sessions);

                //if (!currentUser.isPowerUser) {
                  
                    sessions = sessions.filter(session => new Date(session.date_start) >= new Date());
                //}
                sessions = sessions.filter(session => session.name != "This class is Canceled");
                let AllCohorts = sessions.map(s => s.additional_fields.find(field => field.name === "CohortID").value)
                let uniqueCohorts = [...new Set(AllCohorts)]

                var _groupedSessions = uniqueCohorts.map(cohort => {
                    return { "cohortId": cohort, "sessions": sessions.filter(session => session.additional_fields.find(field => field.name === "CohortID").value == cohort).sort((a, b) => new Date(a.date_start) - new Date(b.date_start)) }
                })
               
                // _groupedSessions = _groupedSessions.sort(function (a, b) {
                //     if (a.sessions[0].name < b.sessions[0].name) {
                //         return -1;
                //     }
                //     if (a.sessions[0].name > b.sessions[0].name) {
                //         return 1;
                //     }
                //     return 0;
                // });

               
                _groupedSessions = _groupedSessions.sort(function (a, b) {
                    const dateA = new Date(a.sessions[0].date_start);
                    const dateB = new Date(b.sessions[0].date_start);
                
                    return dateA - dateB; // Ascending order
                });

                setGroupedSessions(_groupedSessions);

                setFilteredSessions(_groupedSessions);
            }
            else{
                setGroupedSessions([]);
                setFilteredSessions([]);
            }
            setLoading(false);

        } catch (error) {
            console.log(error);
        }
    }
// added by excel soft to filter the sessions based on location selected
    const handleLocationChange = (event) => {
        setselectLocation(event)
      
        var _groupedSessions = JSON.parse(JSON.stringify(groupedSessions));
        const locationValues = event.map((item) =>
        item.value
      );
        const getSelectedLocationObj = locations.filter(
          (item) =>  locationValues.includes(item.name) 
        );
    if(event.length == 0)
    {
        setFilteredSessions(groupedSessions)
    }
    else{
      
        if(filteredSessions && filteredSessions.length > 0)
        {
            const getFilteredSessions = filteredSessions.filter(s =>  getSelectedLocationObj.some(x => x.id === s.sessions[0].location?.id) );
            setFilteredSessions([...getFilteredSessions]);
           if(sessionFromDate === "" && sessionToDate === "")
           {
            const getFilteredSessions = _groupedSessions.filter(s => getSelectedLocationObj.some(x => x.id === s.sessions[0].location?.id));
            setFilteredSessions([...getFilteredSessions]);
           }
        
        }
        else{
            const getFilteredSessions = _groupedSessions.filter(s => getSelectedLocationObj.some(x => x.id === s.sessions[0].location?.id));
            setFilteredSessions([...getFilteredSessions]);
        }
      
        //const getFilteredSessions = filteredSessions.filter(
       //     (item) =>
       //     item.sessions.location && item.sessions.location.id === (getSelectedLocationObj[0] ? getSelectedLocationObj[0].id : null)
       //   );

        //  const getFilteredSessions = filteredSessions.filter((parent) => {
         //   return getSelectedLocationObj?.some(
          //    (item) => item?.id === parent?.sessions?.location?.id
          //  );
         // });

         //setFilteredSessions([...getFilteredSessions]);
    }
       
       
      };
// added by excel soft to get the locations
    const getAllLocations = async () => {
      //  const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/location/all${window.location.search}`, { withCredentials: true });
const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/location`,
        {
            "currentPage": 0,
            "pageSize": 0
          }
      );
        const _locations = response.data.items;
       // const _locations = response.data;
        const options = _locations.map(loc => ({
            label: loc.name,
            value: loc.name 
            }));
           
        setlocationOptions(options);
   
        setLocations(_locations);
     
    }

    // added by excel soft to get the locations
    const getAllLanguages = async () => {
      
       
        const response = await axios.post(
         `${process.env.REACT_APP_API_BASE_URL}/language`
       
       );
       
    
        setLanguages(response.data);
      
     }

    const getUserLearningPlans = async (query) => {
        const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/learningplan${window.location.search}${query ? `&id_user=${query}` : ""}`, { withCredentials: true });
        const learningPlanIds = response.data.items.map(lp => lp.id);
        setUserLearningPlans(learningPlanIds);
    }

    const onPageClick = async (page) => {
        getLearningPlans(search, page);
    }

    const onLpClick = async (learningPlan) => {
        setLoading(true);
      //  await getAllCoursesSessions(learningPlan);
      await getAllCoursesSessionsForSessionPage(learningPlan);
      
        setSelectedPlan(learningPlan);
    }

    // const onTpClick = async (trainingProgram) => {
    //     setSelectedTrainingProgram(trainingProgram);
    //     //let trainingProgramWithCode = trainingProgram + language;
    //     getLearningPlans(trainingProgram);
    //     setGoToPrograms(false)
    // }

    const backFromDetails = () => {
        setSelectedPlan();
    }

    const viewMyCourse = () => {
        setSelectedPlan();
        setCompletePage(false);
    }
    const okClickAfterEnrolled = () => {
        setSelectedPlan();
        setCompletePage(true);
    }

    const handleLanguageChange = (event) => {
        let langCode = event.target.value
        setLanguage(langCode);


        //let trainingProgramWithCode = selectedTrainingProgram + langCode;
        var plans = JSON.parse(JSON.stringify(learningPlans));
        plans = plans.filter(lp => lp.code.endsWith(langCode))
        setFilteredPlans(plans);

    }

    const handleNetworkChange = (event) => {
        var _groupedSessions = JSON.parse(JSON.stringify(groupedSessions));



        if (sessionFromDate) {
            _groupedSessions = _groupedSessions.filter(s => new Date(s.sessions[0].start_date) >= new Date(sessionFromDate));
        }

        setFilteredSessions(_groupedSessions);
    }
//inside method date_start is chnaged to previous name convertion
    const handleSessionDateChange = (event) => {       
        var _groupedSessions = JSON.parse(JSON.stringify(groupedSessions));

        if (event.target.id === "fromDate") {

            let fromDate = `${event.target.value} 00:00:00`;
            setSessionFromDate(fromDate)

            if (event.target.value === "") {
                _groupedSessions = _groupedSessions;
            } else {
                _groupedSessions = _groupedSessions.filter(s => new Date(s.sessions[0].date_start) >= new Date(fromDate));

            }

            //apply toDate filter
            if (sessionToDate) {
                _groupedSessions = _groupedSessions.filter(s => new Date(s.sessions[0].date_start) <= new Date(sessionToDate));
            }
        }
        else {
            let toDate = `${event.target.value} 23:59:59`;
            setSessionToDate(toDate)

            if (event.target.value === "") {
                _groupedSessions = _groupedSessions;
            } else {
                _groupedSessions = _groupedSessions.filter(s => new Date(s.sessions[0].date_start) <= new Date(toDate));
            }

            //apply fromDate filter
            if (sessionFromDate) {
                _groupedSessions = _groupedSessions.filter(s => new Date(s.sessions[0].date_start) >= new Date(sessionFromDate));
            }
        }
        setFilteredSessions(_groupedSessions);
    }

    return (
        <>
            <div className="row p-1 text-end align-middle">
                <div className="col text-end">

                    {selectedPlan ?
                        <>
                        <div className="d-flex justify-content-between">
                        
                        <MultiSelect
        options={locationOptions}
        value={selectLocation}
        onChange={(e)=>{handleLocationChange(e)}}
        labelledBy="Select"
    className="custom-width-multiselect"
    overrideStrings={{ "selectSomeItems": "Select location" }}
    hasSelectAll={false}
      />
                          <div>
                            <span className="form-label ps-3  pe-2">from:</span>
                            <input
                              id="fromDate"
                              type="date"
                              onChange={(event) => handleSessionDateChange(event)}
                              className="date-picker"
                            />
          
                            <span className="form-label ps-3 pe-2">to:</span>
                            <input
                              id="toDate"
                              type="date"
                              onChange={(event) => handleSessionDateChange(event)}
                              className="date-picker"
                            />
                          </div>
                        </div>
                      </>
                        :
                        <>
                            <span className="form-label pe-2">Select Language:</span>
                            {languages.length !== 0 ? (
    <select
        defaultValue="EN"
        value={language}
        onChange={(event) => handleLanguageChange(event)}
        style={{ width: 150 }}
        className="form-select form-select-sm d-inline"
    >
        {languages.languages.map((lang) => (
            <option value={lang.languagecode} key={lang.languagecode}>
                {lang.languagename}
            </option>
        ))}
    </select>
) : (
    <select
        value={language}
        onChange={(event) => handleLanguageChange(event)}
        style={{ width: 150 }}
        className="form-select form-select-sm d-inline"
    >
        <option value="">No languages available</option>
    </select>
)}
                        </>
                    }
                </div>
            </div>
            {/* <div className="row">
                {!selectedPlan && <LearningPlanSearch onChange={setSearch} />}
            </div> */}
            <div className="row">

                {loading ?
                    <><div className="container p-3 mt-3"><div className="row"><h6 className="text-center color-grey pt-5"><Spinner animation="border" /></h6></div></div> </> : (
                        selectedPlan ?
                            <Sessions sessions={filteredSessions} sessionsForCourse={groupedSessionsForCourseId} learningPlan={selectedPlan} locations={locations} courseIds={coursesIds} onBackClick={backFromDetails} onOkClick={okClickAfterEnrolled}/>
                            :
                            CompletePage?
                            <Complete onViewMyCourseClick={viewMyCourse}></Complete>
                            :
                            <LearningPlanList learningPlans={filteredPlans} onLpClick={onLpClick} onPageClick={onPageClick} />
                    )}
            </div>

        </>
    )
}

export default LearningPlans;